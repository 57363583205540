import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';
import { SmallListButtonComponent } from './mpx-small-list-button.component';
import { SmallListColComponent } from './mpx-small-list-col.component';
import { SmallListItemComponent } from './mpx-small-list-item.component';
import { SmallListTitleComponent } from './mpx-small-list-title.component';
import { SmallListComponent } from './mpx-small-list.component';

@NgModule({
    imports: [CommonModule, TranslocoModule, MatButtonModule, MatTooltipModule, MatDividerModule, MatIconModule],
    declarations: [
        SmallListComponent,
        SmallListTitleComponent,
        SmallListItemComponent,
        SmallListButtonComponent,
        SmallListColComponent
    ],
    exports: [
        SmallListComponent,
        SmallListTitleComponent,
        SmallListItemComponent,
        SmallListButtonComponent,
        SmallListColComponent
    ]
})
export class SmallListModule {}
