<div class="border-b flex flex-row h-16 items-center place-content-around">
    <div class="text-base font-medium">{{ 'NOTIFICATIONS.TITLE' | transloco | uppercase }}</div>

    <button
        *ngIf="notificationService.notifications.length"
        mat-icon-button
        [matTooltip]="'NOTIFICATIONS.MARK_ALL_AS_READ' | transloco"
        (click)="markAllAsRead()"
    >
        <mat-icon>mark_email_read</mat-icon>
    </button>

    <button mat-icon-button routerLink="/setting/user-account" [matTooltip]="'NOTIFICATIONS.SETTINGS' | transloco">
        <mat-icon>settings</mat-icon>
    </button>
</div>

<div *ngIf="!notificationService.notifications.length; else hasNotifications" class="my-4 pl-4">
    {{ 'NOTIFICATIONS.NO_NOTIF' | transloco | uppercase }}
</div>

<ng-template #hasNotifications>
    <mat-list class="pt-0 divide-y">
        <mat-list-item
            *ngFor="let notif of notificationService.notifications"
            class="min-h-24 h-auto"
            [ngClass]="{ 'primary-100': !notif.read, 'primary-200': !notif.seen }"
            (click)="onClickNotification(notif)"
        >
            <div class="flex flex-row items-center">
                <div class="mr-4">
                    <mat-icon mat-list-icon>{{ notif.icon }}</mat-icon>
                </div>

                <div class="flex flex-col py-1">
                    <span mat-line class="wrap mb-2 text-sm" [innerHTML]="notif.label | transloco: notif.data"></span>

                    <span mat-line class="wrap text-xs">
                        {{ 'NOTIFICATIONS.RELATIVE_TIME' | transloco: { diff: notif.relativeTime } }}
                    </span>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</ng-template>
