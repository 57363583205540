<mpx-navbar
    class="md:w-auto fixed md:sticky left-0 top-0 bottom-0"
    [ngClass]="{
        'w-full': isOpen$ | async,
        'w-auto': (isOpen$ | async) === false
    }"
></mpx-navbar>

<!-- Wrapper -->
<div id="layout-vertical-navigation" class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <mpx-toolbar></mpx-toolbar>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <router-outlet></router-outlet>
    </div>

    <fuse-sidebar class="fixed" name="quickPanel" position="right" id="quick-panel">
        <mpx-notification-panel></mpx-notification-panel>
    </fuse-sidebar>
</div>
