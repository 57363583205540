<h1 mat-dialog-title>{{ 'VAULT_MODAL.TITLE' | transloco }}</h1>

<mat-dialog-content>
    <p>{{ 'VAULT_MODAL.CONTENT' | transloco }}</p>
    <div class="flex justify-center items-center">
        <mat-progress-spinner [value]="spinnerProgress"></mat-progress-spinner>
        <div class="absolute">
            {{ countdown * 1000 | date: 'mm:ss' }}
        </div>
    </div>
</mat-dialog-content>
